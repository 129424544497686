<template>
  <div class="ztnrbg">

    <div class="index_mv ztnr">
      <leftMeun></leftMeun>
      <div class="right_nr">

        <div class="list category_news_article"
             style="border: 0;">
          <rightMeun></rightMeun>

          <div class="yearlist">
            <div class="content">
              2021年
            </div>
            <div class="content">
              2021年
            </div>
            <div class="content">
              2021年
            </div>
            <div class="content">
              2021年
            </div>
            <div class="content">
              2021年
            </div>
            <div class="content">
              2021年
            </div>
          </div>
        </div>
      </div>
      <newRightMeun></newRightMeun>
    </div>
  </div>
</template>

<script>
import leftMeun from '@/components/leftMeun.vue'
import rightMeun from '@/components/rightMeun.vue'
import newRightMeun from '@/components/newRightMeun.vue'
export default {
  components: {
    leftMeun,
    rightMeun,
    newRightMeun
  },
  data () {
    return {
      data: ''
    }
  },
  mounted () {
    var that=this
    that.$api.page({ id: that.$route.params.newsid }).then(res => {
      that.$emit('getLoad',true);
      if(res.code==1) {
        that.data=res.data
      } else {
        that.data='暂无介绍'
      }
      console.log(that.data)
    }).catch((error) => {
      // error
      console.log(error)
    })
  }
}
</script>

<style>
.yearlist {
  /* padding: 0 20px; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content:space-between; */
}
.yearlist .content {
   display: flex;
   justify-content: center;
   align-items:center;
  margin-right: 39px;
  font-size: 22px;
  margin-top: 60px;
  width: 20%;
  height: 136px;
  background-color: #c6dfe3;
}
.yearlist .content:nth-child(4n) {
  margin-right: 0;
}
</style>